import { useState, useEffect, useRef } from 'react'
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api'
import Fullpage, { FullPageSections, FullpageSection, FullpageNavigation } from '@ap.cx/react-fullpage'
import { auth } from "./firebaseConfig";
import { signOut, signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail, FacebookAuthProvider, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth'
import axios from 'axios'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar';

import './Style.css';

import logo from '../src/img/assets/esmilogo_org.png'
import tarjetas from '../src/img/assets/tarjetas.png'
import { Apple, Basket2, PersonFill } from 'react-bootstrap-icons';

function Home({ path }) {
  const [ showSubscription, setShowSubscription ] = useState( false )

  useEffect(() => {
  }, [])

  /* /////////////////// FULL PAGE STYLE /////////////////////
  const SectionStyle = {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }*/

  return (
    <>
      <div className='home-section'>
        <Container>
          <Row className='align-items-center'>
            <Col md={ 6 }>
              <div>
                <div className='home-title'> Una tarjeta muy diferente </div>
                <div className='home-desc'>We specialized in boosting your brand awareness, quadruple your sales by exponentially generate more leads and conversion</div>
                <br/><br/>
                <Button className='home-button'>Learn More</Button>
              </div>
            </Col>
            <Col md={ 6 }>
              <img src={ tarjetas } className='cards'/>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='simple-section' align="center">
        <div className='section-text'>
          <div className='small-title'> ABOUT </div>
          <div className='section-title-b'> How we can grow your business </div>
          <br/>
          <div className='section-desc'> At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum. </div>
          <div className='space' />

          <Container className='card-service-container p0'>
            <Row>
              <Col md={ 4 } className='card-service-item'>
                <div className='card-service-item'>
                  <div align="left"> <Apple className='card-icon' /> </div>
                  <div className='card-title' >Pay Per Click</div>
                  <div className='card-desc' >Totam rem aperiam, eaque ipsa qu ab illo inventore ver itatis et quasi architecto.</div>
                </div>
              </Col>
              <Col md={ 4 } className='card-service-item' style={{ backgroundColor: '#bf7ee4' }}>
                <div className='card-service-item'>
                  <div align="left"> <Apple className='card-icon' style={{ color: '#fff' }}/> </div>
                  <div className='card-title' style={{ color: '#fff' }}>Search Engine Optimization</div>
                  <div className='card-desc' style={{ color: '#fff' }}>Totam rem aperiam, eaque ipsa qu ab illo inventore ver itatis et quasi architecto.</div>
                </div>
              </Col>
              <Col md={ 4 } className='card-service-item'>
                <div className='card-service-item'>
                  <div align="left"> <Apple className='card-icon' /> </div>
                  <div className='card-title' >Email Marketing</div>
                  <div className='card-desc' >Totam rem aperiam, eaque ipsa qu ab illo inventore ver itatis et quasi architecto.</div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        
        <div className='section-text' style={{ textAlign: 'left' }}>
          <div className='space' />
          <div className='small-title' style={{ textAlign: 'left' }}> METHODOLOGY </div>
          <div className='section-title-b' style={{ textAlign: 'left' }}> How it works </div>
          <div className='section-desc' style={{ textAlign: 'left' }}> Here’s the process we use to generate ROI for our clients: </div>
          
          <Container className='p0'>
            <Row>
              <Col md={ 4 } className='card-service-item'>
                <div>
                  <div align="left"> <Apple className='card-icon' /> </div>
                  <div className='card-title' >Strategy discussion</div>
                  <div className='card-desc' >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore aliqua.</div>
                </div>
              </Col>
              <Col md={ 4 } className='card-service-item'>
                <div>
                  <div align="left"> <Apple className='card-icon' /> </div>
                  <div className='card-title' >Full rollout</div>
                  <div className='card-desc' >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore aliqua.</div>
                </div>
              </Col>
              <Col md={ 4 } className='card-service-item'>
                <div>
                  <div align="left"> <Apple className='card-icon' /> </div>
                  <div className='card-title' >Learn and adjust</div>
                  <div className='card-desc' >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore aliqua.</div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className='simple-section' align="center"></div>

      {/* /////////////////// FULL PAGE SECTION /////////////////////
        <>
          <Fullpage>
            <FullpageNavigation />
            <FullPageSections>

              <FullpageSection style={ SectionStyle }>

                <div><h1>Cerrar Sesión</h1></div>

                <>
                  <Button> Perfil de facturación </Button>
                </>
                
              </FullpageSection>
              <FullpageSection style={ SectionStyle }>2</FullpageSection>
              <FullpageSection style={ SectionStyle }>3</FullpageSection>

            </FullPageSections>

          </Fullpage>
        </>
      */}
    </>
  );
}

export default Home;
