import React, { useState, useEffect, useContext, useRef } from "react"
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

import ClientForm from "./ClientForm"
import { ArrowLeft, Basket2, BoxArrowRight, CreditCard, Person, PersonFill } from "react-bootstrap-icons"

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Offcanvas from 'react-bootstrap/Offcanvas'

import './App.css'
import './Style.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import logo from './img/assets/esmilogo_org.png'

import GlobalContext from "./context/GlobalContext";

const stripePromise = loadStripe("pk_live_51OEy0qGYJdAolFWkwnXGlcRtmrMzyCIO0qe0vlQauTW9sixSTDS2wMySBdZtDg8Wyn2qx9OhViYCdvj35kjplpsV00ilGBZ4Xp");

function Header() {
  const { logged, stripeCostumer, logOut, customerInfo, getCustomerPortal, showPayment, setShowPayment, clientSecret, setClientSecret, handleOnChangeNav } = useContext(GlobalContext)
  const [ activeSubscription, setActiveSubscription ] = useState(null);

  useEffect(() => {    
    if(stripeCostumer && stripeCostumer.subscriptions.data[0])
      setActiveSubscription( stripeCostumer.subscriptions.data[0].items.data[0].price.id )
  }, [stripeCostumer]);

  

  const handleClose = () => {
    setShowPayment(false)
    setClientSecret(null)
  }

  return (
    <div className='App'>

      <Offcanvas show={showPayment} onHide={handleClose} placement={'end'} className="section-price">
        <Offcanvas.Header closeButton>
          <div className="back-btn" onClick={handleClose}>
            <ArrowLeft className="regular-icon"/>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {clientSecret && (
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{clientSecret}}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )}
          { !clientSecret && 
            <ClientForm />
          }
        </Offcanvas.Body>
      </Offcanvas>

      <Navbar expand="lg" className="custom-navbar" fixed="top">
        <Container>
          <Navbar.Brand href="/"> <div> <img className="logo" src={ logo } /> </div> </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll >
            </Nav>

            <Nav.Link className='navbar-item' href="/"> Inicio </Nav.Link>
            <Nav.Link className='navbar-item' onClick={ () => handleOnChangeNav( 'Us' ) }> Nosotros </Nav.Link>
            <Nav.Link href="/cards" className='navbar-item' > Tarjetas </Nav.Link>
            <Nav.Link href="/shopcar" className='navbar-item' > <Basket2 /> </Nav.Link>
              <NavDropdown className='navbar-item' title={ <PersonFill /> } id="basic-nav-dropdown">
                { logged ? 
                  <>
                    <div className="welcome-profile"> ¡Hola { customerInfo ? customerInfo.name.split(" ")[0] : '' }! </div>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={ () => setShowPayment(true) }> <Person className="menu-item-icon" />&nbsp;&nbsp; Mi cuenta </NavDropdown.Item>
                    { activeSubscription && <NavDropdown.Item onClick={ () => getCustomerPortal() }> <CreditCard className="menu-item-icon" />&nbsp;&nbsp; Mi Plan </NavDropdown.Item> }
                    {/* <NavDropdown.Item onClick={ () => getCustomerPortal() }> <CreditCard className="menu-item-icon" />&nbsp;&nbsp; Facturación </NavDropdown.Item> */}
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={ () => logOut() }> <BoxArrowRight className="menu-item-icon" />&nbsp;&nbsp; Cerrar sesión </NavDropdown.Item>
                  </>
                  : 
                  <>
                    <NavDropdown.Item onClick={ () => window.location.href = "/login" }> Iniciar sesión </NavDropdown.Item>
                    <NavDropdown.Item onClick={ () => setShowPayment(true) }> Registrarse </NavDropdown.Item>
                  </>
                }

                
              </NavDropdown>            
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
