import { useState, useEffect, useContext } from 'react'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import './App.css'
import { Facebook, Google } from 'react-bootstrap-icons'

import logo from './img/assets/esmilogo_org.png'

import GlobalContext from "./context/GlobalContext"

var url_string = window.location.href
var url = new URL(url_string)
var pid = url.searchParams.get("pid")

function Login() {
  const [ userName, setUserName ] = useState('')
  const [ password, setPasswrod ] = useState('')

  const { logged, getLogin, searchText, btnDisabled, showForgoten, setShowForgoten, forgotPassword, passReset, wrongCrdentials, setWrongCrdentials, tooManyAttemps, setTooManyAttemps } = useContext(GlobalContext);

  const handleClose = () => setShowForgoten(false);
  const handleOnSubmitForgoten = () => {
    forgotPassword(userName)
  }

  useEffect(() => {
    console.log("btnDisabled", btnDisabled)
    if( logged ) window.location.href = '/'
  }, [ logged ]);

  const newUser = () => {
    sessionStorage.setItem('userForm', true);
    window.location.href = '/'
  }

  return (
    <div className="">
      <Modal show={showForgoten} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-message'>
            <div className="forgoten-pass"> Ingresa el correo que registraste en tu cuenta para mandar instrucciones de reestablecimiento </div>
            <Form.Group className="mb-3" style={{ textAlign: 'left' }}>
              <Form.Label>Usuario</Form.Label>
              <Form.Control required type="text" placeholder="Usuario" value={userName} onChange={ (event) => setUserName(event.target.value) }/>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleOnSubmitForgoten}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={wrongCrdentials} onHide={() => setWrongCrdentials(false)}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-message'>
            <div className="forgoten-pass"> El usuario o contraseña son incorrectos. Favor de verificar sus datos. </div>
            <br/>
            <div>
              <Button variant="primary" onClick={() => setWrongCrdentials(false)}>
                Aceptar
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={tooManyAttemps} onHide={() => setTooManyAttemps(false)}>
        <Modal.Body>
          <div className='modal-message'>
            <div className="forgoten-pass"> El usuario ha quedado temporamente deshabilitado ya que se realizaron muchos intentos de sesion, favor de intentarlo más tarde. </div>
            <br/>
            <div>
              <Button variant="primary" onClick={() => setTooManyAttemps(false)}>
                Aceptar
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div>
        <div align="center">
          <div className="login-container px-3">
            <br/><br/><br/>
            <Container>
              <Row>
                <Col> <div align="center"> <img style={{ width: '90%' }} src={ logo } /> </div> </Col>
              </Row>
              <br/>
              <div className='login-title'> Inicia sesión o regístrate </div>
              <br/>
              <Container>
                <Row>
                  <Col md={12} className='form-style'>
                    <Form>
                      <Form.Group className="mb-3" style={{ textAlign: 'left' }}>
                        <Form.Label>Usuario</Form.Label>
                        <Form.Control required type="text" placeholder="Usuario" value={userName} onChange={ (event) => setUserName(event.target.value) }/>
                      </Form.Group>

                      <Form.Group className="mb-3" style={{ textAlign: 'left' }}>
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control required type="password" placeholder="Contraseña" value={password} onChange={ (event) => setPasswrod(event.target.value) }/>
                      </Form.Group>

                      <div>
                        <Button className='submit-btn' type="submit" disabled={ btnDisabled } onClick={() => getLogin(userName, password, 'normal')}>
                          <Spinner
                            className={ btnDisabled ? '' : 'hide'}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          { searchText }
                        </Button>
                      </div>
                      <br/>

                    </Form>
                  </Col>
                </Row>
              </Container>
            </Container>

            {/* <div style={{ fontSize: "25px", fontWeight: "bold" }}>Iniciar Sesion</div>*/}
            <br/>
            <div className='form-extras-style'>
              <div className="forgoten-pass hight-text" onClick={() => setShowForgoten( true )}>¿Olvidaste tu contraseña?</div>
              <div className="forgoten-pass" onClick={() => newUser()}>¿No tienes cuenta? <span className='hight-text'> Crear nueva cuenta </span></div>
            </div>
            <br/>
          </div>
        </div>
      </div>      
    </div>
  );
}

export default Login;
