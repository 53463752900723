import vibadenti from '../img/NFC/vibadenti.png'
import jardines from '../img/NFC/opticajardines.png'
import jgarcia from '../img/NFC/jgarcia.png'
import ferresistemas from '../img/NFC/ferresistemas.jpg'
import ferre from '../img/NFC/ferre.jpg'
import ferresistemasB from '../img/NFC/ferresistemasB.png'
import ferreB from '../img/NFC/ferreB.png'
import atomy from '../img/NFC/atomy.png'
import lala from '../img/NFC/lala.png'
import almate from '../img/NFC/almate.jpg'
import prov from '../img/NFC/prov.jpg'
import medicalStetic from '../img/NFC/medical_stetic.png'
import zaara from '../img/NFC/zaara.png'
import aldoBautista from '../img/NFC/aldoBautista.png'
import esmitarjeta from '../img/NFC/esmitarjeta.png'
import dupra from '../img/NFC/dupra.jpg'
import condez from '../img/NFC/condez.png'
import capitalhumano from '../img/NFC/capitalhumano.jpg'
import digita from '../img/NFC/digita.png'
import dentalAndCoffee from '../img/NFC/dentalandcoffee.jpg'
import hierroYAcero from '../img/NFC/hierroyacero.png'
import vanguardia from '../img/NFC/vanguardia.png'
import vihtya from '../img/NFC/vihtya.png'
import fare from '../img/NFC/fare.png'

import viridianaBartolano from '../img/NFC/viridiana_bartolano.png'
import veronicaLozano from '../img/NFC/veronica_lozano.jpg'

import HedilbertoCeballos from '../img/NFC/HedilbertoCeballos.png'
import JuanCeballos from '../img/NFC/JuanCeballos.png'
import FernandoDominguez from '../img/NFC/FernandoDominguez.png'
import CandidoIslas from '../img/NFC/CandidoIslas.png'
import LuisCeballos from '../img/NFC/LuisCeballos.png'
import TomyCeballos from '../img/NFC/TomyCeballos.png'
import MiguelIslasR from '../img/NFC/MiguelIslasR.png'
import JoelIslasR from '../img/NFC/JoelIslasR.png'
import RubenYamilReyesV from '../img/NFC/RubenYamilReyesV.png'
import ReginaldoCastillo from '../img/NFC/ReginaldoCastillo.png'
import MitziSanchez from '../img/NFC/MitziSanchez.png'
import LauraCruz from '../img/NFC/LauraCruz.png'
import SandraVarela from '../img/NFC/SandraVarela.png'
import IsraelMunoz from '../img/NFC/IsraelMunoz.png'
import JoselineGuadalupeLopezMoreno from '../img/NFC/JoselineGuadalupeLopezMoreno.png'
import TaniaCeballosCruzM from '../img/NFC/TaniaCeballosCruzM.png'

import JuanCeballosB from '../img/NFC/JuanCeballosB.png'
import FernandoDominguezB from '../img/NFC/FernandoDominguezB.png'
import CandidoIslasB from '../img/NFC/CandidoIslasB.png'
import LuisCeballosB from '../img/NFC/LuisCeballosB.png'
import TomyCeballosB from '../img/NFC/TomyCeballosB.png'
import MiguelIslasRB from '../img/NFC/MiguelIslasRB.png'
import JoelIslasRB from '../img/NFC/JoelIslasRB.png'
import MitziSanchezB from '../img/NFC/MitziSanchezB.png'
import TaniaCeballosCruz from '../img/NFC/TaniaCeballosCruz.png'

import Fermin from '../img/NFC/Fermin.png'
import FerminB from '../img/NFC/FerminB.png'
import Alejandro from '../img/NFC/Alejandro.png'

import esteban from '../img/NFC/esteban.png'


import RaquelBejarano from '../img/NFC/RaquelBejarano.png'
import WGN from '../img/NFC/wgncompany.png'
import WGNB from '../img/NFC/wgncompanyB.png'

import rodrigohernandez from '../img/NFC/rodrigohernandez.png'
import AlbertoDominguez from '../img/NFC/AlbertoDominguez.png'
import SebastianPlataPuebla from '../img/NFC/SebastianPlataPuebla.png'
import LuisEnriqueRamos from '../img/NFC/LuisEnriqueRamos.png'
import MauricioGomez from '../img/NFC/MauricioGomez.png'
import JonathanArenas from '../img/NFC/JonathanArenas.png'
import JosePadilla from '../img/NFC/JosePadilla.png'
import AlfredoRodriguezBarreto from '../img/NFC/AlfredoRodriguezBarreto.png'
import AngelJesusMirandaTorres from '../img/NFC/AngelJesusMirandaTorres.png'
import RobertoFrancoEspinal from '../img/NFC/RobertoFrancoEspinal.png'
import RobertoFranco from '../img/NFC/RobertoFranco.png'
import MarcoArielAguilarBautista from '../img/NFC/MarcoArielAguilarBautista.jpg'

import JuanACruz from '../img/NFC/JuanACruz.jpg'
import Avage from '../img/NFC/avage.png'
import Carmen from '../img/NFC/carmen.png'

//const PATH = 'http://localhost:3000'
const PATH = 'https://esmitarjeta.com.mx/'

export const users = {
    ViridianaBartolano: {
        path: 'ViridianaBartolano',
        component: 'General',
        job: 'Cirujano Dentista',
        profile: viridianaBartolano,
        name: "Viridiana",
        lastName: "Bartolano",
        company: "Vibadenti",
        whats: '55 1900 6574',
        workphone: '55 1900 6574',
        email: 'vibadenti@gmail.com',
        facebook: 'https://www.facebook.com/profile.php?id=100094755978444',
        instagram: null,
        tiktok: null,
        linkedin: null,
        web: null,
        slogan: 'Cuidado dental especializado para niños. Brindamos tratamientos seguros y amigables para garantizar la mejor salud bucal desde temprana edad. 😁🦷 #Odontopediatría #SonrisasFelices.',
        logo: vibadenti,
        loading: vibadenti,
        color: '#ff52ab',
        gallery: null
    },
    MaribelTorres: {
        path: 'MaribelTorres',
        component: 'General',
        job: 'Director General',
        profile: null,
        name: "Maribel",
        lastName: "Torres",
        company: "Grupo Óptico Jardines",
        whats: '55 7667 5716',
        workphone: '55 7667 5716',
        email: 'lab.optico.jardines@gmail.com',
        facebook: 'https://www.facebook.com/GrupoOpticoJardines',
        instagram: 'https://www.instagram.com/opticajardines/',
        tiktok: 'https://www.tiktok.com/@opticajardines',
        linkedin: null,
        web: 'https://grupoopticojardines.com.mx',
        slogan: 'Somos especialistas de atención primaria en salud ocular, con un grupo de optometristas de primer nivel, que van a garantizar la mejor atención para determinar cuál es la mejor solución que se adapte a sus necesidades.',
        logo: jardines,
        loading: jardines,
        color: '#00799e',
        gallery: null
    },
    MaribelTorrest: {
        path: 'MaribelTorrest',
        component: 'General',
        job: 'Director General',
        profile: null,
        name: "Maribel",
        lastName: "Torres",
        company: "Grupo Óptico Jardines",
        whats: '56 2139 7439',
        workphone: '55 8191 2834',
        email: 'lab.optico.jardines@gmail.com',
        facebook: 'https://www.facebook.com/GrupoOpticoJardines',
        instagram: 'https://www.instagram.com/opticajardines/',
        tiktok: 'https://www.tiktok.com/@opticajardines',
        linkedin: null,
        web: 'https://grupoopticojardines.com.mx',
        slogan: 'Somos especialistas de atención primaria en salud ocular, con un grupo de optometristas de primer nivel, que van a garantizar la mejor atención para determinar cuál es la mejor solución que se adapte a sus necesidades.',
        logo: jardines,
        loading: jardines,
        color: '#00799e',
        gallery: null
    },
    AdrianaMancera: {
        path: 'AdrianaMancera',
        component: 'General',
        job: 'Consultor Profesional en Previsión',
        profile: null,
        name: "Adriana",
        lastName: "Mancera",
        company: "J. García López",
        whats: '55 6148 0299',
        workphone: '55 5196 7414',
        email: 'amacera213@gmail.com',
        facebook: null,
        instagram: null,
        tiktok: null,
        linkedin: null,
        web: null,
        slogan: 'La vida merece un homenaje',
        logo: jgarcia,
        loading: jgarcia,
        color: '#b28405',
        gallery: [
            {
                src: PATH + '/adrianamancera/adrianamancera2.jpg',
                width: 556,
                height: 1280
            },
            {
                src: PATH + '/adrianamancera/adrianamancera1.jpg',
                width: 720,
                height: 689
            },
            {
                src: PATH + '/adrianamancera/adrianamancera3.jpg',
                width: 1080,
                height: 1080
            }
        ]
    },
    /*HedilbertoCeballos: {
        path: 'HedilbertoCeballos',
        component: 'General',
        job: '',
        profile: HedilbertoCeballos,
        name: "Hedilberto",
        lastName: "Ceballos",
        company: "Ferresistemas",
        whats: '55 3988 7972',
        workphone: '55 3988 7972',
        email: 'hceballos@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    JuanCeballos: {
        path: 'JuanCeballos',
        component: 'General',
        job: '',
        profile: JuanCeballos,
        name: "Juan",
        lastName: "Ceballos",
        company: "Ferresistemas",
        whats: '55 3988 7988',
        workphone: '55 3988 7988',
        email: 'juan.ceballos@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    TomyCeballos: {
        path: 'TomyCeballos',
        component: 'General',
        job: '',
        profile: TomyCeballos,
        name: "Tomy",
        lastName: "Ceballos",
        company: "Ferresistemas",
        whats: '55 3104 1675',
        workphone: '55 3104 1675',
        email: 'tomas.ceballos@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    LuisCeballos: {
        path: 'LuisCeballos',
        component: 'General',
        job: '',
        profile: LuisCeballos,
        name: "Luis",
        lastName: "Ceballos",
        company: "Ferresistemas",
        whats: '55 4922 1895',
        workphone: '55 4922 1895',
        email: 'luis.ceballos@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    CandidoIslas: {
        path: 'CandidoIslas',
        component: 'General',
        job: '',
        profile: CandidoIslas,
        name: "Candido",
        lastName: "Islas",
        company: "Ferresistemas",
        whats: '55 2728 8568',
        workphone: '55 2728 8568',
        email: 'candido.islas@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    FernandoDominguez: {
        path: 'FernandoDominguez',
        component: 'General',
        job: '',
        profile: FernandoDominguez,
        name: "Fernando",
        lastName: "Dominguez",
        company: "Ferresistemas",
        whats: '55 8022 9586',
        workphone: '55 8022 9586',
        email: 'luis.dominguez@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },

    MiguelIslasR: {
        path: 'MiguelIslasR',
        component: 'General',
        job: '',
        profile: MiguelIslasR,
        name: "Miguel",
        lastName: "Islas R.",
        company: "Ferresistemas",
        whats: '55 4566 7863',
        workphone: '55 4566 7863',
        email: 'miguel.islas@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    JoelIslasR: {
        path: 'JoelIslasR',
        component: 'General',
        job: '',
        profile: JoelIslasR,
        name: "Joel",
        lastName: "Islas R.",
        company: "Ferresistemas",
        whats: '55 2903 6040',
        workphone: '55 2903 6040',
        email: 'joel.islas@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    ReginaldoCastillo: {
        path: 'ReginaldoCastillo',
        component: 'General',
        job: '',
        profile: ReginaldoCastillo,
        name: "Reginaldo",
        lastName: "Castillo",
        company: "Ferresistemas",
        whats: '55 3402 3500',
        workphone: '55 3402 3500',
        email: 'regianaldo.castillo@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    RubenYamilReyesV: {
        path: 'RubenYamilReyesV',
        component: 'General',
        job: 'Gerencia de Desarrollo de Negocios',
        profile: RubenYamilReyesV,
        name: "Ruben Yamil",
        lastName: "Reyes V.",
        company: "Ferresistemas",
        whats: '55 1949 1717',
        workphone: '55 1949 1717',
        email: 'ruben.yamil@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    HedilbertoCeballosF: {
        path: 'HedilbertoCeballosF',
        component: 'General',
        job: '',
        profile: HedilbertoCeballos,
        name: "Hedilberto",
        lastName: "Ceballos",
        company: "Ferresistemas",
        whats: '55 3988 7972',
        workphone: '55 3988 7972',
        email: 'hceballos@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    JuanCeballosF: {
        path: 'JuanCeballosF',
        component: 'General',
        job: '',
        profile: JuanCeballosB,
        name: "Juan",
        lastName: "Ceballos",
        company: "Ferresistemas",
        whats: '55 3988 7988',
        workphone: '55 3988 7988',
        email: 'juan.ceballos@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    TomyCeballosF: {
        path: 'TomyCeballosF',
        component: 'General',
        job: '',
        profile: TomyCeballosB,
        name: "Tomy",
        lastName: "Ceballos",
        company: "Ferresistemas",
        whats: '55 3104 1675',
        workphone: '55 3104 1675',
        email: 'tomas.ceballos@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    LuisCeballosF: {
        path: 'LuisCeballosF',
        component: 'General',
        job: '',
        profile: LuisCeballosB,
        name: "Luis",
        lastName: "Ceballos",
        company: "Ferresistemas",
        whats: '55 4922 1895',
        workphone: '55 4922 1895',
        email: 'luis.ceballos@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    CandidoIslasF: {
        path: 'CandidoIslasF',
        component: 'General',
        job: '',
        profile: CandidoIslasB,
        name: "Candido",
        lastName: "Islas",
        company: "Ferresistemas",
        whats: '55 2728 8568',
        workphone: '55 2728 8568',
        email: 'candido.islas@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    FernandoDominguezF: {
        path: 'FernandoDominguezF',
        component: 'General',
        job: '',
        profile: FernandoDominguezB,
        name: "Fernando",
        lastName: "Dominguez",
        company: "Ferresistemas",
        whats: '55 8022 9586',
        workphone: '55 8022 9586',
        email: 'luis.dominguez@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    MiguelIslasRF: {
        path: 'MiguelIslasRF',
        component: 'General',
        job: '',
        profile: MiguelIslasRB,
        name: "Miguel",
        lastName: "Islas R.",
        company: "Ferresistemas",
        whats: '55 4566 7863',
        workphone: '55 4566 7863',
        email: 'miguel.islas@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    JoelIslasRF: {
        path: 'JoelIslasRF',
        component: 'General',
        job: '',
        profile: JoelIslasRB,
        name: "Joel",
        lastName: "Islas R.",
        company: "Ferresistemas",
        whats: '55 2903 6040',
        workphone: '55 2903 6040',
        email: 'joel.islas@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    FerminFrancoAvalos: {
        path: 'FerminFrancoAvalos',
        component: 'General',
        job: 'Ventas',
        profile: Fermin,
        name: "Fermín",
        lastName: "Franco Avalos",
        company: "Ferresistemas",
        whats: '55 8420 9688',
        workphone: '55 8420 9688',
        email: 'fermin.franco@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',

        gallery: null
    },
    FerminFrancoAvalosF: {
        path: 'FerminFrancoAvalosF',
        component: 'General',
        job: 'Ventas',
        profile: FerminB,
        name: "Fermín",
        lastName: "Franco Avalos",
        company: "Ferresistemas",
        whats: '55 8420 9688',
        workphone: '55 8420 9688',
        email: 'fermin.franco@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    MitziSanchezFuentesM: {
        path: 'MitziSanchezFuentesM',
        component: 'General',
        job: 'Ventas Mostrador',
        profile: MitziSanchezB,
        name: "Mitzi",
        lastName: "Sánchez Fuentes",
        company: "Ferresistemas",
        whats: '56 5100 9574',
        workphone: '56 5100 9574',
        email: 'mitzi.sanchez@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    LauraCruzGonzalez: {
        path: 'LauraCruzGonzalez',
        component: 'General',
        job: 'Auxiliar administrativo',
        profile: LauraCruz,
        name: "Laura",
        lastName: "Cruz Gonzalez",
        company: "Ferresistemas",
        whats: '55 2512 7824',
        workphone: '55 5884 1909',
        email: 'laura.cruz@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    TaniaCeballosCruzM: {
        path: 'TaniaCeballosCruzM',
        component: 'General',
        job: '',
        profile: TaniaCeballosCruzM,
        name: "Tania",
        lastName: "Ceballos Cruz",
        company: "Ferresistemas",
        whats: '55 4099 9651',
        workphone: '55 4099 9651',
        email: 'tania.ceballos@ferresistemas.com.mx ',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Manufactura de partes metalmecánicas para el sector automotriz, aeronáutico e industria en general.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    JoselineGuadalupeLopezMoreno : {
        path: 'JoselineGuadalupeLopezMoreno',
        component: 'General',
        job: null,
        profile: JoselineGuadalupeLopezMoreno,
        name: "Joseline Guadalupe",
        lastName: "López Moreno",
        company: "Ferresistemas",
        whats: '56 5100 9574',
        workphone: '56 5100 9574',
        email: 'proyectos@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: ferresistemas,
        loading: ferre,
        color: '#ff7102',
        gallery: null
    },
    AlejandroCruzPerez: {
        path: 'AlejandroCruzPerez',
        component: 'General',
        job: 'Jefe de Almacén',
        profile: Alejandro,
        name: "Alejandro",
        lastName: "Cruz Pérez",
        company: "Ferresistemas",
        whats: '55 4386 8903',
        workphone: '55 4386 8903',
        email: 'almacen@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    RubenYamilReyesVF: {
        path: 'RubenYamilReyesVF',
        component: 'General',
        job: 'Gerencia de desarrollo de negocios',
        profile: RubenYamilReyesV,
        name: "Ing. Rubén Yamil",
        lastName: "Reyes V.",
        company: "Ferresistemas",
        whats: '55 1949 1717',
        workphone: '55 1949 1717',
        email: 'ruben.yamil@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    MitziSanchezFuentes: {
        path: 'MitziSanchezFuentes',
        component: 'General',
        job: 'Ventas Mostrador',
        profile: MitziSanchez,
        name: "Mitzi",
        lastName: "Sánchez Fuentes",
        company: "Ferresistemas",
        whats: '56 5100 9574',
        workphone: '56 5100 9574',
        email: 'mitzi.sanchez@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    SandraVarelaV: {
        path: 'SandraVarelaV',
        component: 'General',
        job: 'Cuentas por paga',
        profile: SandraVarela,
        name: "Sandra",
        lastName: "Varela V.",
        company: "Ferresistemas",
        whats: '55 8012 9408',
        workphone: '55 5884 1909',
        email: 'sandra.varela@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    IsraelMunozH: {
        path: 'IsraelMunozH',
        component: 'General',
        job: 'Administración y Finanzas',
        profile: IsraelMunoz,
        name: "Israel",
        lastName: "Muñoz H.",
        company: "Ferresistemas",
        whats: '55 4386 8543',
        workphone: '55 5884 1909',
        email: 'israel.munoz@ferresistemas.com.mx',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    TaniaCeballosCruz: {
        path: 'TaniaCeballosCruz',
        component: 'General',
        job: '',
        profile: TaniaCeballosCruz,
        name: "Tania",
        lastName: "Ceballos Cruz",
        company: "Ferresistemas",
        whats: '55 4099 9651',
        workphone: '55 4099 9651',
        email: 'tania.ceballos@ferresistemas.com.mx ',
        facebook: 'https://www.facebook.com/GrupoFerresistemas',
        instagram: 'https://www.instagram.com/grupoferresistemas/',
        tiktok: null,
        linkedin: null,
        web: 'https://ferresistemas.com.mx/',
        slogan: 'Somos una empresa dedicada a la distribución de productos para sistemas de conducción industriales y líneas de distribución de agua potable a través de un modelo de innovación, tecnología y sustentabilidad.',
        logo: ferresistemasB,
        loading: ferreB,
        color: '#ff7102',
        gallery: null
    },
    */
    RaquelBejaranoGarcia: {
        path: 'RaquelBejaranoGarcia',
        component: 'General',
        job: '',
        profile: RaquelBejarano,
        name: "Raquel",
        lastName: "Bejarano Raquel",
        company: "WGN Company",
        whats: '55 1949 1717',
        workphone: '55 2859 4316',
        email: 'alipotec27@hotmail.com',
        facebook: 'https://www.facebook.com/BELLAJATZA',
        instagram: 'https://www.instagram.com/raquelbejaranogarcia',
        tiktok: 'https://www.tiktok.com/@raquelgarcia244',
        linkedin: null,
        web: 'http://raquelgarciastore.com/',
        slogan: 'Centro de distribución y venta. Estado de México',
        logo: WGNB,
        loading: WGN,
        color: '#1c372c',
        gallery: null
    },

    GuadalupeRios: {
        path: 'GuadalupeRios',
        component: 'General',
        job: 'Representante Oficial',
        profile: null,
        name: "Guadalupe",
        lastName: "Rios",
        company: "",
        whats: '56 1276 6288',
        workphone: '56 1276 6288',
        email: 'lupitariosreglado@hotmail.com',
        facebook: 'https://www.facebook.com/guadalupe.riosreglado',
        instagram: 'https://www.instagram.com/riosreglado/',
        tiktok: null,
        linkedin: null,
        x: null,
        youtube: null,
        web: null,
        slogan: 'Atomy es una empresa global de distribución directa y efectiva entre fabricantes y consumidores a nivel mundial. Proporciona productos de buena calidad a precios accesibles que permiten ahorrar y ganar dinero a los consumidores. De acuerdo con su éxito, ATOMY ocupa el puesto número 10 en la lista de las mejores 100 empresas globales a nivel mundial en tan solo 14 años desde su fundación en 2009. Además, su trabajo está respaldado por los laboratorios "Kolmas BNH" (instituto de investigación de energía atómica de Corea), "Kaeri", una empresa de cosméticos con más de 100 años de estudio en productos de excelente calidad para diferentes marcas.',
        logo: atomy,
        loading: atomy,
        color: '#00b4ef',
        gallery: null
    },
    EulaliaGomez: {
        path: 'EulaliaGomez',
        component: 'General',
        job: '',
        profile: null,
        name: "Eulalia",
        lastName: "Gómez",
        company: "NSL",
        whats: '55 4048 6529',
        workphone: '55 4048 6529',
        email: 'lalagome_09@hotmail.com',
        facebook: 'https://www.facebook.com/nssaludbella',
        instagram: null,
        tiktok: null,
        linkedin: null,
        web: 'https://nskn.co/EHnVTy',
        slogan: 'Somos un equipo de distribución de tecnología de rejuvenecimiento genético a nivel mundial.',
        logo: lala,
        loading: lala,
        color: '#01738b',
        gallery: null
    },
    VeronicaLozano: {
        path: 'VeronicaLozano',
        component: 'General',
        job: '',
        profile: veronicaLozano,
        name: "Verónica",
        lastName: "Lozano Jiménez",
        company: "Álmate",
        whats: '55 1384 0816',
        workphone: '55 1384 0816',
        email: 'veronika.lozano87@gmail.com',
        facebook: 'https://www.facebook.com/AlmateVeLo',
        instagram: null,
        tiktok: null,
        linkedin: null,
        telegram: 'https://t.me/+dFldArAMrCNmNTlh',
        web: null,
        slogan: 'Terapias Holisticas, Cursos y Talleres de crecimiento personal.',
        logo: almate,
        loading: almate,
        color: '#b7bc31',
        gallery: null,
        services: 'https://drive.google.com/file/d/1AB53jP6tvjr8egThzXG-_laiLAP5WxxO/view?usp=sharing',
    },
    JuanVargas: {
        path: 'JuanVargas',
        component: 'General',
        job: '',
        profile: null,
        name: "Juan José",
        lastName: "Vargas",
        company: "ProvServTec",
        whats: '55 1854 0198',
        workphone: '55 1854 0198',
        email: 'juanjose@provservtec.com.mx',
        facebook: 'https://www.facebook.com/provservtec.com.mx',
        instagram: null,
        tiktok: null,
        linkedin: null,
        web: 'https://provservtec.com.mx/',
        slogan: 'Te ayudamos a llevar tu negocio al siguiente nivel, con equipo profesional y de diversas modalidades para impresión y etiquetado, consumibles e insumos, codificación, marcaje y trazabilidad.',
        logo: prov,
        loading: prov,
        color: '#2f4997',
        gallery: null
    },
    ElyCastroAleman: {
        path: 'ElyCastroAleman',
        component: 'General',
        job: 'Director Médico Medical & Stetic',
        profile: null,
        name: "Dra. Ely",
        lastName: "Castro Alemán",
        company: "Medical & Stetic",
        whats: '22 2158 2036',
        workphone: '22 2158 2036',
        email: 'dra.castro18@hotmail.com',
        facebook: null,
        instagram: 'https://www.instagram.com/medical.stetic/?igshid=MzRlODBiNWFlZA%3D%3D',
        tiktok: null,
        linkedin: null,
        web: null,
        slogan: '',
        logo: medicalStetic,
        loading: medicalStetic,
        color: '#c65091',
        gallery: null
    },
    RafaelGomez: {
        path: 'RafaelGomez',
        component: 'General',
        job: 'COO',
        profile: null,
        name: "Rafael",
        lastName: "Gómez",
        company: "",
        whats: '55 3022 5314',
        workphone: '55 3022 5314',
        email: 'contacto@zaaracondos.com',
        facebook: null,
        instagram: null,
        tiktok: null,
        linkedin: null,
        web: 'https://zaaracondos.com',
        slogan: '',
        logo: zaara,
        loading: zaara,
        color: '#f05741',
        gallery: null
    },
    AldoUrielBautistaMillan: {
        path: 'AldoUrielBautistaMillan',
        component: 'General',
        job: 'Asesor de seguros',
        profile: null,
        name: "Aldo Uriel",
        lastName: "Bautista Millán",
        company: "ProvServTec",
        whats: '55 3223 4036',
        workphone: '55 3223 4036',
        email: 'aldobautista@hotmail.com',
        facebook: null,
        instagram: 'https://www.instagram.com/asesoraldobautista/',
        tiktok: 'https://www.tiktok.com/@asesoraldobautista',
        linkedin: 'https://www.linkedin.com/in/aldobautista74/',
        web: 'https://xontacto.com/aldo-bautista',
        slogan: 'Como asesor de ventas, mi enfoque se basa en escuchar tus necesidades individuales, analizar tu situación y diseñar planes de ahorro y protección personalizados que te brinden seguridad y tranquilidad. ¡Conversemos y juntos construyamos un futuro financiero sólido!"',
        logo: aldoBautista,
        loading: aldoBautista,
        color: '#039ee2',
        gallery: null
    },
    EstebanEspejel: {
        path: 'EstebanEspejel',
        component: 'General',
        job: 'Director de Ventas',
        profile: esteban,
        name: "Esteban",
        lastName: "Espejel",
        company: "Es mi tarjeta",
        whats: '56 4282 2242',
        workphone: '56 4282 2242',
        email: 'info@esmitarjeta.com.mx',
        facebook: 'https://www.facebook.com/profile.php?id=100094682870211',
        instagram: null,
        tiktok: null,
        linkedin: null,
        web: null,
        slogan: 'Tarjetas inteligentes que con solo acercarla a un dispositivo compatible📲, puede transferir instantáneamente su información de contacto.',
        logo: esmitarjeta,
        loading: esmitarjeta,
        color: '#7a2873',
        gallery: null
    },
    CarlosDuran: {
        path: 'CarlosDuran',
        component: 'General',
        job: 'Director General',
        profile: null,
        name: "Carlos",
        lastName: "Duran",
        company: "DUPRA Systems",
        whats: '55 1048 2313',
        workphone: '55 5264 4058',
        email: 'cduran@duprasystems.com',
        facebook: null,
        instagram: null,
        tiktok: null,
        linkedin: null,
        web: 'https://duprasystems.com',
        slogan: 'Somos especialistas en telecomunicaciones, con más de 12 años de experiencia. Trabajamos en conjunto con nuestros clientes para establecer solidas alianzas de negocio, diseñando así soluciones de vanguardia',
        logo: dupra,
        loading: dupra,
        color: '#003a62',
        gallery: null
    },
    VladDeLaCruz: {
        path: 'VladDeLaCruz',
        component: 'General',
        job: 'Innovación y Desarrollo',
        profile: null,
        name: "Vlad",
        lastName: "De La Cruz",
        company: "DUPRA Systems",
        whats: '55 4899 7082',
        workphone: '55 5264 4058',
        email: 'vdcruz@duprasystems.com',
        facebook: null,
        instagram: null,
        tiktok: null,
        linkedin: null,
        web: 'https://duprasystems.com',
        slogan: 'Somos especialistas en telecomunicaciones, con más de 12 años de experiencia. Trabajamos en conjunto con nuestros clientes para establecer solidas alianzas de negocio, diseñando así soluciones de vanguardia',
        logo: dupra,
        loading: dupra,
        color: '#003a62',
        gallery: null
    },
    RodrigoHernandez: {
        path: 'RodrigoHernandez',
        component: 'General',
        job: 'Comercial e Infraestructura',
        profile: rodrigohernandez,
        name: "Rodrigo",
        lastName: "Hernández",
        company: "DUPRA Systems",
        whats: '55 1048 2314',
        workphone: '55 5264 4058',
        email: 'rhernandez@duprasystems.com',
        facebook: null,
        instagram: null,
        tiktok: null,
        linkedin: null,
        web: 'https://duprasystems.com',
        slogan: 'Somos especialistas en telecomunicaciones, con más de 12 años de experiencia. Trabajamos en conjunto con nuestros clientes para establecer solidas alianzas de negocio, diseñando así soluciones de vanguardia',
        logo: dupra,
        loading: dupra,
        color: '#003a62',
        gallery: null
    },
    CondeZarateAsociados: {
        path: 'CondeZarateAsociados',
        component: 'General',
        job: '',
        profile: null,
        name: "Conde Zárate & Asociados",
        lastName: "",
        company: "Conde Zárate & Asociados",
        whats: '55 5332 5317',
        workphone: '55 5332 5317',
        email: 'despachocondezarate@outlook.com',
        facebook: 'https://www.facebook.com/profile.php?id=100057309015417&mibextid=ZbWKwL',
        instagram: 'https://instagram.com/condezarateyasociados?igshid=MWNobWg0ZGRldDhhMg==',
        tiktok: null,
        linkedin: null,
        web: null,
        slogan: 'Somos una organización que lleva a cabo todas las actividades relacionadas con la contabilidad como revisar movimientos de ingresos y gastos, preparar informes fiscales, registrar cambios monetarios y calcular nóminas bajo las Normas de Información Financiera y cambios fiscales de la Reforma Fiscal vigente. Mejorando la toma de decisiones y potenciando la administración de tu negocio, brindando asistencia con un servicio preciso y confiable.',
        logo: condez,
        loading: condez,
        color: '#0f5593',
        gallery: null
    },
    CapitalHumano: {
        path: 'CapitalHumano',
        component: 'General',
        job: '',
        profile: null,
        name: "Centro de Profesionalización de Capital Humano",
        lastName: "",
        company: "Centro de Profesionalización de Capital Humano",
        whats: '55 1254 3594',
        workphone: '55 1254 3594',
        email: 'cza.cevaluacion@gmail.com',
        facebook: 'https://www.facebook.com/profile.php?id=100086499862025&mibextid=LQQJ4d',
        instagram: 'https://www.instagram.com/cpch._/?igshid=MzRlODBiNWFlZA%3D%3D',
        tiktok: 'https://www.tiktok.com/@cpch._?_t=8gPBnUngamM&_r=1',
        x: 'https://twitter.com/centro_cpch?t=7vFKLVWxLE0wXqpPsUm32A&s=09',
        linkedin: null,
        web: null,
        slogan: 'Somos una organización dedicada a la capacitación, certificación en competencias laborales y Microsoft, bajo estándares de calidad. Desarrollando diferentes metodologías de aprendizaje, en diversas áreas del sector productivo, a fin de cubrir las necesidades específicas de especialización del trabajador para el logro de los objetivos de su organización, generando conjuntamente en los empleados un plan de vida y carrera.',
        logo: capitalhumano,
        loading: capitalhumano,
        color: '#0f5593',
        gallery: null
    },
    RicardoOtero: {
        path: 'RicardoOtero',
        component: 'General',
        job: 'Director Soporte Pro Monterrey',
        profile: null,
        name: "Ricardo",
        lastName: "Otero",
        company: "DIGITA",
        whats: '55 2020 7752',
        workphone: '812 7460 750',
        email: 'ricardo@digita.com.mx',
        facebook: 'https://www.facebook.com/DigitaDT?locale=es_LA',
        instagram: 'https://www.instagram.com/definiciontotal',
        tiktok: null,
        linkedin: null,
        web: 'https://www.digita.com.mx/',
        slogan: null,
        logo: digita,
        loading: digita,
        color: '#003a62',
        gallery: null
    },
    LuisRMartinez: {
        path: 'LuisRMartinez',
        component: 'General',
        job: 'Ingeniero Soporte Pro',
        profile: null,
        name: "Luis R.",
        lastName: "Martínez",
        company: "DIGITA",
        whats: '812 098 0294',
        workphone: '812 7460 751',
        email: 'l.martinez@digita.com.mx',
        facebook: 'https://www.facebook.com/DigitaDT?locale=es_LA',
        instagram: 'https://www.instagram.com/definiciontotal',
        tiktok: null,
        linkedin: null,
        web: 'https://www.digita.com.mx/',
        slogan: null,
        logo: digita,
        loading: digita,
        color: '#003a62',
        gallery: null
    },
    EdgardoLopez: {
        path: 'EdgardoLopez',
        component: 'General',
        job: 'Ejecutivo de Ventas',
        profile: null,
        name: "Edgardo",
        lastName: "López",
        company: "DIGITA",
        whats: '812 758 0110',
        workphone: '812 7460 752',
        email: 'edgardo@digita.com.mx',
        facebook: 'https://www.facebook.com/DigitaDT?locale=es_LA',
        instagram: 'https://www.instagram.com/definiciontotal',
        tiktok: null,
        linkedin: null,
        web: 'https://www.digita.com.mx/',
        slogan: null,
        logo: digita,
        loading: digita,
        color: '#003a62',
        gallery: null
    },
    AlbertoDominguez: {
        path: 'AlbertoDominguez',
        component: 'General',
        job: 'Cirujano Dentista',
        profile: AlbertoDominguez,
        name: "Alberto",
        lastName: "Dominguez",
        company: "Dental & Coffee",
        whats: '55 1646 6282',
        workphone: '55 1646 6282',
        email: null,
        facebook: 'https://www.facebook.com/DentalAndCoffee',
        instagram: 'https://www.instagram.com/dentalandcoffee/',
        tiktok: null,
        linkedin: null,
        web: null,
        slogan: '¡Rescatando sonrisas desde hace 25 años! Más de 1,000 pacientes nos recomiendan. Atención especial a niños y personas nerviosas.',
        logo: dentalAndCoffee,
        loading: dentalAndCoffee,
        color: '#003a62',
        gallery: null
    },
    BrayanTorrijos: {
        path: 'BrayanTorrijos',
        component: 'General',
        job: 'Hierro y Acero',
        profile: null,
        name: "Brayan",
        lastName: "Torrijos",
        company: "Hierro y Acero",
        whats: '56 4851 5014',
        workphone: '56 4851 5014',
        email: 'herreriayaceromx@gmail.com',
        facebook: 'https://www.facebook.com/HierroyAceroMX',
        instagram: null,
        tiktok: null,
        linkedin: null,
        web: null,
        slogan: 'En HIERRO Y ACERO, nos especializamos en 👉portones automatizados, 👉rejacero y 👉trabajos de herrería de alta calidad. Nos distinguimos por nuestra formalidad y dedicación a la excelencia. Descubre la seguridad y el estilo que tu hogar merece.',
        logo: hierroYAcero,
        loading: hierroYAcero,
        color: '#000',
        gallery: null
    },
    JonathanArenas: {
        path: 'JonathanArenas',
        component: 'General',
        job: 'Presidente',
        profile: JonathanArenas,
        name: "Jonathan",
        lastName: "Arenas",
        company: "Vanguardia DH",
        whats: '56 5088 9856',
        workphone: '56 5088 9856',
        email: 'jon_270711@outlook.com',
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        car: 'Volkswagen Bora',
        placas: 'NLD1544',
        status: true
    },
    SebastianPlataPuebla: {
        path: 'SebastianPlataPuebla',
        component: 'General',
        job: 'Director estatal',
        profile: SebastianPlataPuebla,
        name: "Sebastian",
        lastName: "Puebla Plata",
        company: "Vanguardia DH",
        whats: '55 2762 7155',
        workphone: '56 5088 9856',
        email: 'sebas123platas@gmail.com',
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        car: 'Toyota Hilux 2022',
        placas: 'LF55940',
        status: true
    },
    LuisEnriqueRamos: {
        path: 'LuisEnriqueRamos',
        component: 'General',
        job: 'Director de Gestoria Social',
        profile: LuisEnriqueRamos,
        name: "Luis Enrique",
        lastName: "Ramos",
        company: "Vanguardia DH",
        whats: null,
        workphone: '56 5088 9856',
        email: null,
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        status: true
    },
    MauricioGomez: {
        path: 'MauricioGomez',
        component: 'General',
        job: 'Secretario de Salud Social',
        profile: MauricioGomez,
        name: "Mauricio",
        lastName: "Gómez",
        company: "Vanguardia DH",
        whats: null,
        workphone: '56 5088 9856',
        email: null,
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        status: true
    },
    JosePadilla: {
        path: 'JosePadilla',
        component: 'General',
        job: 'Director Regional',
        profile: JosePadilla,
        name: "José",
        lastName: "Padilla",
        company: "Vanguardia DH",
        whats: '55 3655 2092',
        workphone: '56 5088 9856',
        email: null,
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        car: 'FIAT ARGO 2023',
        placas: 'MJJ870A',
        status: true
    },
    AlfredoRodriguezBarreto: {
        path: 'AlfredoRodriguezBarreto',
        component: 'General',
        job: 'Coordinador de Gestión Social',
        profile: AlfredoRodriguezBarreto,
        name: "Alfredo",
        lastName: "Rodríguez Barreto",
        company: "Vanguardia DH",
        whats: null,
        workphone: null,
        email: null,
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        status: true
    },
    AngelJesusMirandaTorres: {
        path: 'AngelJesusMirandaTorres',
        component: 'General',
        job: 'Gestor Social',
        profile: AngelJesusMirandaTorres,
        name: "Ángel Jesús",
        lastName: "Miranda Torres",
        company: "Vanguardia DH",
        whats: null,
        workphone: null,
        email: null,
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        status: true
    },
    RobertoFrancoEspinal: {
        path: 'RobertoFrancoEspinal',
        component: 'General',
        job: 'Gestor Social',
        profile: RobertoFrancoEspinal,
        name: "Roberto",
        lastName: "Franco Espinal",
        company: "Vanguardia DH",
        whats: null,
        workphone: null,
        email: null,
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        status: true
    },
    RobertoFranco: {
        path: 'RobertoFranco',
        component: 'General',
        job: 'Presidente Sede tultitlán',
        profile: RobertoFranco,
        name: "Roberto",
        lastName: "Franco Espinal",
        company: "Vanguardia DH",
        whats: '56 4720 9204',
        workphone: null,
        email: 'francoroberto792@gmail.com',
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        status: true
    },
    MarcoArielAguilarBautista: {
        path: 'MarcoArielAguilarBautista',
        component: 'General',
        job: 'Gestor Social',
        profile: MarcoArielAguilarBautista,
        name: "Marco Ariel",
        lastName: "Aguilar Bautista",
        company: "Vanguardia DH",
        whats: null,
        workphone: null,
        email: null,
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        status: true
    },
    MarcoArielAguilarBautista: {
        path: 'MarcoArielAguilarBautista',
        component: 'General',
        job: 'Gestor Social',
        profile: MarcoArielAguilarBautista,
        name: "Marco Ariel",
        lastName: "Aguilar Bautista",
        company: "Vanguardia DH",
        whats: null,
        workphone: null,
        email: null,
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        status: true
    },
    PabloMonroyEspejel: {
        path: 'PabloMonroyEspejel',
        component: 'General',
        job: 'Director de Medios',
        profile: null,
        name: "Pablo",
        lastName: "Monroy Espejel",
        company: "Vanguardia DH",
        whats: '55 4347 3835',
        workphone: '55 4347 3835',
        email: null,
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        car: 'Suzuki Swift',
        placas: '',
        status: true
    },
    AngelTrujillo: {
        path: 'AngelTrujillo',
        component: 'General',
        job: 'Director de Medios',
        profile: null,
        name: "Angel",
        lastName: "Trujillo Trejo",
        company: "Vanguardia DH",
        whats: '55 4525 4079',
        workphone: '55 4525 4079',
        email: null,
        facebook: 'https://www.facebook.com/VanguardiaDH',
        instagram: 'https://www.instagram.com/vanguardiadh/',
        tiktok: null,
        linkedin: null,
        web: 'https://vanguardiadh.com',
        slogan: 'Somos una asociación no gubernamental, altruista y dedicada a la mejora de la sociedad, estableciendo vínculos tanto con autoridades como con la comunidad.',
        logo: vanguardia,
        loading: vanguardia,
        color: '#213970',
        gallery: null,
        car: null,
        placas: null,
        status: true
    },
    HectorAguayo: {
        path: 'HectorAguayo',
        component: 'General',
        job: 'Logística',
        profile: null,
        name: "Héctor",
        lastName: "Aguayo",
        company: "Vihtya",
        whats: '55 3821 3972',
        workphone: '55 3821 3972',
        email: 'vihtyasalasydeco@gmail.com',
        facebook: 'https://www.facebook.com/VihtyaMueblesyDecoracion',
        instagram: 'https://www.instagram.com/sm_vihtya/',
        tiktok: null,
        linkedin: null,
        web: 'https://vihtya.com.mx',
        slogan: 'Somos una empresa 100% mexicana, con más de 10 años de experiencia en la fabricación y comercialización de Salas, Comedores y decoración para el hogar. En Vihtya, fusionamos la tradición artesanal con diseños modernos yvanguardistas para crear espacios únicos y acogedores.',
        logo: vihtya,
        loading: vihtya,
        color: '#a4a7b8',
        gallery: null
    },
    YolandaEspitia: {
        path: 'YolandaEspitia',
        component: 'General',
        job: 'Administración',
        profile: null,
        name: "Yolanda",
        lastName: "Espitia",
        company: "Vihtya",
        whats: '56 1665 3718',
        workphone: '56 1665 3718',
        email: 'vihtyasalasydeco@gmail.com',
        facebook: 'https://www.facebook.com/VihtyaMueblesyDecoracion',
        instagram: 'https://www.instagram.com/sm_vihtya/',
        tiktok: null,
        linkedin: null,
        web: 'https://vihtya.com.mx',
        slogan: 'Somos una empresa 100% mexicana, con más de 10 años de experiencia en la fabricación y comercialización de Salas, Comedores y decoración para el hogar. En Vihtya, fusionamos la tradición artesanal con diseños modernos yvanguardistas para crear espacios únicos y acogedores.',
        logo: vihtya,
        loading: vihtya,
        color: '#a4a7b8',
        gallery: null
    },
    OfeliaSoto: {
        path: 'OfeliaSoto',
        component: 'General',
        job: 'Ventas',
        profile: null,
        name: "Ofelia",
        lastName: "Soto",
        company: "Vihtya",
        whats: '55 5984 4586',
        workphone: '55 5984 4586',
        email: 'vihtyasalasydeco@gmail.com',
        facebook: 'https://www.facebook.com/VihtyaMueblesyDecoracion',
        instagram: 'https://www.instagram.com/sm_vihtya/',
        tiktok: null,
        linkedin: null,
        web: 'https://vihtya.com.mx',
        slogan: 'Somos una empresa 100% mexicana, con más de 10 años de experiencia en la fabricación y comercialización de Salas, Comedores y decoración para el hogar. En Vihtya, fusionamos la tradición artesanal con diseños modernos yvanguardistas para crear espacios únicos y acogedores.',
        logo: vihtya,
        loading: vihtya,
        color: '#a4a7b8',
        gallery: null
    },
    FareTramitesIMSS: {
        path: 'FareTramitesIMSS',
        component: 'General',
        job: 'Tramite de pensiones y soluciones administrativas IMSS',
        profile: null,
        name: "FARE",
        lastName: "",
        company: "FARE",
        whats: '55 4816 4659',
        workphone: '55 4816 4659',
        email: 'FARE_SAIMSS@outlook.com',
        facebook: 'https://www.facebook.com/share/rztZ6Amx18r9Rpve/?mibextid=WC7FNe',
        instagram: 'https://www.instagram.com/fare_serviciosadministrativos/',
        tiktok: null,
        linkedin: null,
        web: null,
        slogan: "En FARE podemos ayudarte con: · Asesoría personalizada · Búsqueda de semanas cotizadas · Aclaraciones de créditos fiscales · Convenios · Condonación de multas · Corrección de datos · Unificación de números del IMSS · Proyección de pensiones · Restablecimientos · Desbloqueo de cuentas · Trámite de pensión · Modalidad 40 · Cálculo de pensión",
        logo: fare,
        loading: fare,
        color: '#205d51',
        gallery: null
    },
    FarePensionesIMSS: {
        path: 'FarePensionesIMSS',
        component: 'General',
        job: 'Tramite de pensiones y soluciones administrativas IMSS',
        profile: null,
        name: "FARE",
        lastName: "",
        company: "FARE",
        whats: '55 4844 2429',
        workphone: '55 4844 2429',
        email: 'FARE_SAIMSS@outlook.com',
        facebook: 'https://www.facebook.com/share/rztZ6Amx18r9Rpve/?mibextid=WC7FNe',
        instagram: 'https://www.instagram.com/fare_serviciosadministrativos/',
        tiktok: null,
        linkedin: null,
        web: null,
        slogan: "En FARE podemos ayudarte con: · Asesoría personalizada · Búsqueda de semanas cotizadas · Aclaraciones de créditos fiscales · Convenios · Condonación de multas · Corrección de datos · Unificación de números del IMSS · Proyección de pensiones · Restablecimientos · Desbloqueo de cuentas · Trámite de pensión · Modalidad 40 · Cálculo de pensión",
        logo: fare,
        loading: fare,
        color: '#205d51',
        gallery: null
    },
    JuanACruz: {
        path: 'JuanACruz',
        component: 'General',
        job: 'Área Comercial',
        profile: JuanACruz,
        name: "Juan A.",
        lastName: "Cruz",
        company: "FARE",
        whats: '56 3984 9793',
        workphone: '56 3984 9793',
        email: 'comercial@avage.com.mx',
        emailAlt: 'administracion@avage.com.mx',
        facebook: null,
        instagram: null,
        tiktok: null,
        linkedin: null,
        web: null,
        slogan: <><div>Avage, somos una Empresa especializada en la venta de Productos Químicos; nos interesa ser partícipes en el crecimiento del negocio de nuestros clientes.<div><br/><b>"Colaboramos en el crecimiento de su negocio"</b></div></div></>,
        logo: Avage,
        loading: Avage,
        color: '#2e97b1',
        textColor: '#505050',
        gallery: null,
        address: <><div>Tlatlaya 13,</div><div>Centro Urbano, 54700,</div><div>Cuautitlán Izcalli, Estado de México'</div></>,
        map: 'https://maps.app.goo.gl/co53JK5CkshgZRpR7',
        productList: [ 'Ácido Cítrico', 'Ácido Esteárico Triple Prensado', 'Ácido Fumárico', 'Ácido Oxálico', 'Ácido Sulfámico', 'Potasa Cáustica Escamas', 'Sosa Cáustica Escamas', 'Sulfosuccinatos', 'Ésteres de Ácidos Grasos', 'Ésteres de Glicoles', 'Ésteres de Octil Dodecanol', 'Ésteres de Glicerilo', 'Ésteres de Sorbitán', 'Ésteres de Polietilen Glicol' ] 
    },
    CarmenGonzalezRodriguez: {
        path: 'CarmenGonzalezRodriguez',
        component: 'General',
        job: 'Asesora de seguros ',
        profile: null,
        name: "Carmen",
        lastName: "González Rodríguez",
        company: "Carmen González",
        whats: '55 7612 3144',
        workphone: '55 7612 3144',
        email: 'segurosconcarmen@gmail.com',
        emailAlt: null,
        facebook: 'https://www.facebook.com/SegurosconCarmen',
        instagram: 'https://www.instagram.com/segurosconcarmen/',
        tiktok: null,
        linkedin: null,
        web: null,
        slogan: <><div></div></>,
        logo: Carmen,
        loading: Carmen,
        color: '#003d71',
        textColor: null,
        gallery: null,
        address: null,
        map: null,
        serviceList: [ 'Retiro', 'Educación', 'Vida', 'Persona Clave', 'Gastos Médicos', 'Autos', 'Hogar', 'Pláticas, conferencias y talleres' ] 
    }
}