import { useState, useEffect, useRef } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"

import { ferreList } from './json/ferresistemas'
import { israelList } from './json/israel'
import { opticasList } from './json/opticas'
import { generalList } from './json/grl'
import Ferresistemas from './Ferresistemas'
import Home from './Home'
import Payment from './CheckoutForm'
import Return from './Return'
import Israel from './Israel'
import Opticas from './Opticas'
import Artista from './Artista'
import Grl from './Grl'
import NFC from './NFC'
import Login from './Login'
import Header from './Header'

function App() {
  const [ sectionSelected, setSectionSelected ] = useState('/')
  const [ component, setComponent ] = useState(<Ferresistemas />)

  useEffect(() => {
    var path = window.location.pathname.replace(/\//g, '')

    if( ferreList.includes( path ) ) {
      setComponent(<Ferresistemas path={ path.replace('-', '').replace('/', '') } />)
    } else if( israelList.includes( path ) ) {
      setComponent(<Israel path={ path.replace('-', '').replace('/', '') } />)
    } else if( opticasList.includes( path ) ) {
      setComponent(<Opticas path={ path.replace('-', '').replace('/', '') } />)
    } else if( generalList.includes( path ) ) {
      setComponent(<Grl path={ path.replace('-', '').replace('/', '') } />)
    } else if( path.includes( 'perfiles' ) ) {
      if( path === 'perfilesAlfredoRodr%C3%ADguezBarreto' ) window.location.href = '/perfiles/AlfredoRodriguezBarreto'
      setComponent(<NFC path={ path.replace('-', '').replace('perfiles', '') } />)
    }

    setSectionSelected(window.location.pathname)
  }, []);

  return (
    <>
      { (!window.location.pathname.includes('/login') && !window.location.pathname.includes('/perfiles')) &&
        <Header />
      }

        <BrowserRouter>
          <Routes>
            <Route path="/" element={ <Home /> } />
            <Route path="/login" element={ <Login /> } />
            <Route path="/return" element={ <Return /> } />
            <Route path='/Andreasantana' element={ <Artista /> } />
            <Route path='/Andreasantana/' element={ <Artista /> } />
            <Route path={ sectionSelected } element={ component } />
          </Routes>
        </BrowserRouter>
    </>
    
  );
}

export default App;
